@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap');

:root{
  --bgColorMain: #1C1C1C;
  --bgColorDarker: #0B0B0B;
  --bgColorLighter: #2D2D2D;

  --bgColorBarelyDarker: #1B1B1B;
  --bgColorBarelyLighter: #1D1D1D;

  --trimColorMain: #F9FFFF;
  --trimColorDarker: #E8EEEE;
  --trimColorLighter: #FFFFFF;

  --bgColorGraphMain: #1B1B1B;
  --bgColorGraphDarker: #0A0A0A;
  --bgColorGraphLighter: #2C2C2C;
  
  --trimColorGraphMain: #2962FF;
  --trimColorGraphDarker: #1851EE;
  --trimColorGraphLighter: #3A73FF;
}

body{
  background: var(--bgColorBarelyDarker);
  padding: 2vmin;
  padding-top: 0.5vmin;
}

.MuiGrid-root{
  color: var(--trimColorMain);
  background: linear-gradient(180deg, var(--bgColorBarelyDarker) 0%, var(--bgColorBarelyLighter) 50%, var(--bgColorMain) 100%);
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: min(1.9vh, 1.05em);
  color: var(--trimColorMain);
  background: linear-gradient(180deg, var(--bgColorBarelyDarker) 0%, var(--bgColorMain) 50%, var(--bgColorBarelyDarker) 100%);
}

td, th {
  border: 1px solid;
  border-color: var(--trimColorMain);
  color: var(--trimColorMain);
  padding: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: 400;
  font-size: 1.05em;
}

h5 {
  font-family: 'Yantramanav', sans-serif;
}

tr{
  color: var(--trimColorMain) !important;
  border-color: var(--trimColorMain) !important;
}

span {
  color: var(--trimColorMain) !important;
}

.MuiTable-root{
  color: var(--trimColorMain) !important;
  border-color: var(--trimColorMain) !important;
}

.MuiTableCell-root{
  color: var(--trimColorMain) !important;
  border-color: var(--trimColorMain) !important;
}

.MuiTableCell-body{
  color: var(--trimColorMain) !important;
  border-color: var(--trimColorMain) !important;
}

tr:nth-child(even) {
  background-color: var(--bgColorBarelyLighter) !important;
}

.fullWidth {
  width: 100%;
}

.widener {
  display: inline-block;
  width: clamp(200px, 10em, 1920px);
}

.chrome-picker input{
  background-color: #f9fdff;
  color: black;
}

input {
  color: var(--trimColorMain);
  font-family: 'Yantramanav', sans-serif;
  font-weight: 400;
  font-size: 0.9em;
  background-color: var(--bgColorLighter);
  border: none;
}

input:disabled {
  color: var(--trimColorDarker);
  background-color: var(--bgColorBarelyDarker);
  border: none;
}

input[type=text]:focus {
  background-color: var(--bgColorBarelyDarker);
  border: none;
  outline: none;
}

button {
  background: linear-gradient(180deg, var(--bgColorBarelyDarker) 0%, var(--bgColorMain) 50%, var(--bgColorBarelyDarker) 100%);
  width: 100%;
  height: 100%;
  border: 1px outset #595d5f;
  padding: 0px;
  color: var(--trimColorMain);
  text-align: center;
  display: inline-block;
  font-weight: 400;
  font-size: 0.8em;
  font-family: 'Yantramanav', sans-serif;
}

button:hover{
  background: var(--bgColorLighter);
  cursor: pointer;
}

button:active {
  background: var(--bgColorDarker);
}

.react-dropdown-select-dropdown{
  background: var(--bgColorMain) !important;
}

.stickyTable {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  z-index: 9999;
}


.chartContainer {
  position: -webkit-sticky; /* Safari */
  position: sticky;
}

.chart-legend {
	width: 196px;
	height: 70px;
	position: absolute;
	padding: 8px;
  margin: 0px 4px;
	font-size: 19px;
  color: var(--trimColorMain);
	text-align: left;
	z-index: 1000;
	pointer-events: none;
}

.tfSelector {
  background: linear-gradient(180deg, var(--bgColorBarelyDarker) 0%, var(--bgColorMain) 50%, var(--bgColorBarelyDarker) 100%);
  display: block;
  position: absolute;
  z-index: 100;
  left: 15px;
  bottom: 37px;
  border: 1px outset #595d5f;
  padding: 3px;
  color: var(--trimColorMain);
  text-align: center;
  display: inline-block;
  font-weight: 400;
  font-size: 0.8em;
  font-family: 'Yantramanav', sans-serif;
}

.tfSelectorOption{
  background: var(--bgColorMain);
  color: var(--trimColorMain);
}

.textArea{
  background: var(--bgColorLighter);
  color: var(--trimColorMain);
  height: 20px;
  width: 195px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 0.9em;
  font-family: 'Yantramanav', sans-serif;
}

.tickerHoverTooltip {
  color: var(--trimColorMain);
  font-size: 1.5em;
  text-align: left;
  z-index: 1000;
  pointer-events: none;
  letter-spacing: 0.5px;
  font-family: 'Yantramanav', sans-serif;
}


.rotate{
  position: absolute;
  top: 0;
  left: 0;
  bottom: -100%;
  right: 0;
  margin: auto;
  animation: rotation 1.3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}